<template>
  <div>
    <span v-on:click="closeSideNav" class="close-side-navigation">
      <i class="material-icons">fast_rewind</i>
    </span>
    <span v-on:click="openSideNav" class="open-side-navigation">
      <i class="material-icons">fast_forward</i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'SideNavToggle',
  methods: {
    closeSideNav() {
      this.$store.dispatch('closeSideNav');
    },
    openSideNav() {
      this.$store.dispatch('openSideNav');
    },
  },
};
</script>

<style lang="scss">
.close-side-navigation,
.open-side-navigation {
  display: none;
}

@media print, screen and (min-width:40em) {
  .close-side-navigation,
  .open-side-navigation {
    display: inline-block;
    float: right;
    margin-top: 60px;
    margin-right: -2px;
    cursor: pointer;
    font-size: 28px;
    color: #555;

    &:hover,
    &:focus,
    &:active {
      color: #f7941e;
    }
  }

  .close-side-navigation {
    right: 5px;
  }

  .open-side-navigation {
    margin-right: -2px;
  }

  .side-navigation--closed .close-side-navigation {
    display: none;
  }

  .open-side-navigation {
    display: none;
  }

  .side-navigation--closed .open-side-navigation {
    display: inline-block;
  }
}
</style>
