import Vue from 'vue';
import Vuex from 'vuex';
import axios from '../axios';
// eslint-disable-next-line
import router from '../router';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    idToken: null,
    userId: null,
    birdCage: null,
    loginOpen: false,
    globalMessage: '',
    sideNavClosed: false,
    absoluteHeader: true,
    mobileNavOpen: false,
    computedHeight: 0,
    currentPropertyId: 0,
    currentUserId: 0,
    currentUserName: '',
    currentLastLogIn: '',
    currentRegionId: 0,
  },
  mutations: {
    clearHeight(state) {
      state.computedHeight = 0;
    },
    computeHeight(state, heightData) {
      state.computedHeight = heightData.height;
    },
    authUser(state, userData) {
      state.idToken = userData.token;
      state.userId = userData.userId;
      state.currentUserName = userData.userName;
      state.currentLastLogIn = userData.lastLogIn;
      state.birdCage = userData.birdCage;
    },
    clearAuthData(state) {
      state.idToken = null;
      state.userId = null;
      state.userName = '';
      state.birdCage = null;
    },
    openLoginBox(state) {
      state.loginOpen = true;
    },
    closeLoginBox(state) {
      state.loginOpen = false;
    },
    emptyGM(state) {
      state.globalMessage = '';
    },
    setGM(state, gmData) {
      state.globalMessage = gmData.gm;
    },
    closeSideNav(state) {
      state.sideNavClosed = true;
    },
    openSideNav(state) {
      state.sideNavClosed = false;
    },
    shiftHeader(state) {
      state.absoluteHeader = false;
    },
    toggleMobileNav(state, directionData) {
      if (directionData.direction === 'open') {
        state.mobileNavOpen = true;
      } else {
        state.mobileNavOpen = false;
      }
    },
    setUser(state, user) {
      state.currentUserId = user.id;
    },
  },
  actions: {
    clearHeight({ commit }) {
      commit('clearHeight');
    },
    computeHeight({ commit }) {
      const theBody = document.body;
      const html = document.documentElement;
      const height = Math.max(theBody.scrollHeight, theBody.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight);
      commit('computeHeight', { height });
    },
    getToken({ commit }, authData) {
      axios.get(`/UserAuth/getToken/${authData.theHash}/${authData.theCn}.json`)
        .then((res) => {
          commit('authUser', {
            token: res.data.response.token,
            userId: res.data.response.user,
            birdCage: res.data.response.cage,
            userName: res.data.response.username,
            lastLogIn: res.data.response.lastlogin,
          });
          const expirationDate = res.data.response.expiresIn;
          localStorage.setItem('token', res.data.response.token);
          localStorage.setItem('userId', res.data.response.user);
          localStorage.setItem('birdCage', res.data.response.cage);
          localStorage.setItem('expiryDate', expirationDate);
          localStorage.setItem('userName', res.data.response.username);
          localStorage.setItem('lastLogIn', res.data.response.lastlogin);
          router.push({ path: '/' });
        });
    },
    checkStoredToken({ commit }) {
      const localToken = localStorage.getItem('token');
      const localUserId = localStorage.getItem('userId');
      const localCage = localStorage.getItem('birdCage');
      const localExpiryDate = localStorage.getItem('expiryDate');
      const localUserName = localStorage.getItem('userName');
      const localLastLogIn = localStorage.getItem('lastLogIn');
      const now = new Date();
      const ts = Math.round((now.getTime() / 1000));
      if (localToken && ts <= localExpiryDate) {
        commit('authUser', {
          token: localToken,
          userId: localUserId,
          birdCage: localCage,
          userName: localUserName,
          lastLogIn: localLastLogIn,
        });
      }
    },
    logout({ commit }) {
      commit('setGM', {
        gm: 'You are now logged out of Customised Clothing Admin',
      });
      commit('clearAuthData');
      localStorage.removeItem('token');
      localStorage.removeItem('expiryDate');
      localStorage.removeItem('userId');
      localStorage.removeItem('birdCage');
      localStorage.removeItem('userName');
      localStorage.removeItem('lastLogIn');
      router.push({ path: '/' });
    },
    toggleMobileNav({ commit }, directionData) {
      commit('toggleMobileNav', {
        direction: directionData.direction,
      });
    },
    closeSideNav({ commit }) {
      commit('closeSideNav');
    },
    openSideNav({ commit }) {
      commit('openSideNav');
    },
    openLogin({ commit }) {
      commit('openLoginBox');
    },
    closeLogin({ commit }) {
      commit('closeLoginBox');
    },
    emptyGlobalMessage({ commit }) {
      commit('emptyGM');
    },
    shiftHeader({ commit }) {
      commit('shiftHeader');
    },
    setUser({ commit }, userId) {
      commit('setUser', {
        id: userId,
      });
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.idToken !== null;
    },
    loginOpen(state) {
      return state.loginOpen === true;
    },
    globalMessage(state) {
      return state.globalMessage;
    },
    sideNavClosed(state) {
      return state.sideNavClosed === true;
    },
    authData(state) {
      const authorizationData = {};
      authorizationData.user = state.userId;
      authorizationData.cage = state.birdCage;
      authorizationData.token = state.idToken;
      return authorizationData;
    },
    absoluteHeader(state) {
      return state.absoluteHeader;
    },
    mobileNavOpen(state) {
      return state.mobileNavOpen;
    },
    computedHeight(state) {
      return state.computedHeight;
    },
    currentUserId(state) {
      return state.userId;
    },
    currentUserName(state) {
      return state.currentUserName;
    },
    currentLastLogIn(state) {
      return state.currentLastLogIn;
    },
  },
});
