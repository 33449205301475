<template>
  <div
    class="pad-top--small">
    <app-logo></app-logo>
    <nav
      class="side-navigation">
      <ul class="side-navigation__group">
        <router-link
          to="/"
          title="Dashboard"
          tag="li"
          class="side-navigation__item">
          <i class="material-icons">dashboard</i>
          Dashboard
        </router-link>
        <router-link
          to="/view-all-orders"
          title="View all orders"
          tag="li"
          class="side-navigation__item">
          <i class="material-icons">assignment</i>
          All Orders
        </router-link>
        <router-link
          to="/customers"
          title="View customers"
          tag="li"
          class="side-navigation__item">
          <i class="material-icons">assignment</i>
          Customers
        </router-link>
        <router-link
          to="/view-all-garments"
          title="View garments"
          tag="li"
          class="side-navigation__item">
          <i class="material-icons">bookmark</i>
          All Garments
        </router-link>
        <router-link
          to="/view-shop-garments"
          title="View shop garments"
          tag="li"
          class="side-navigation__item">
          <i class="material-icons">bookmark</i>
          On Website
        </router-link>
        <router-link
          to="/abandoned-carts"
          title="Abandoned carts"
          tag="li"
          class="side-navigation__item">
          <i class="material-icons">assignment</i>
          Abandoned Carts
        </router-link>
        <li
          v-on:click="logout()"
          class="side-navigation__item">
          <i class="material-icons">exit_to_app</i>
          log Out
        </li>
      </ul>
    </nav>
    <app-side-nav-toggle></app-side-nav-toggle>
  </div>
</template>

<script>
import Logo from '@/components/SystemNavigation/TopLogo.vue';
import SideNavToggle from '@/components/Elements/SideNavToggle.vue';

export default {
  name: 'DashboardNav',
  components: {
    appLogo: Logo,
    appSideNavToggle: SideNavToggle,
  },
  data() {
    return {
      computedHeight: 0,
    };
  },
};
</script>
