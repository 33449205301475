<template>
  <div></div>
</template>

<script>
export default {
  name: 'GetToken',
  data() {
    return {
      auth: false,
    };
  },
  methods: {
    getToken() {
      const theHash = this.$route.query.hash;
      const theCn = this.$route.query.cn;
      this.$store.dispatch('getToken', { theHash, theCn });
    },
  },
  created() {
    this.getToken();
  },
};
</script>
