<template>
  <div>
    <div v-if="authenticated" class="dashboard-container">
      <div
        v-bind:class="{ 'side-navigation--closed': sideNavClosed, 'mobile-open': mobileNavOpen }"
        class="side-navigation__container">
        <app-side-nav></app-side-nav>
      </div>
      <div
        v-bind:class="{ 'side-navigation--closed': sideNavClosed }"
        class="module__container">
        <app-header></app-header>
        <div class="grid-container pad-top">
          <div class="grid-x grid-margin-x">
          <div class="cell small-12">
            <h2>Recent Orders</h2>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell small-12">
            <table>
              <thead>
                <tr>
                  <th>Order no</th>
                  <th>Customer</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(order, index) in recentOrders" v-bind:key="index">
                  <td>{{ order.id }}</td>
                  <td>{{ order.customer }}</td>
                  <td>{{ order.date | prettyDate }}</td>
                  <td>{{ order.amount | priceInPounds | currency }}</td>
                  <td>{{ order.status }}</td>
                  <td>
                    <router-link v-bind:to="`/view-order/${order.id}`">
                      <i class="material-icons">visibility</i>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else="">
      <div class="grid-container pad-top">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-8 medium-offset-2">
            <app-login></app-login>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Login from '@/components/Auth/Login.vue';
import Header from '@/components/Header/SystemTopNavigation.vue';
import SideNav from '@/components/SystemNavigation/DashboardNav.vue';
import axios from '../../axios';

export default {
  name: 'Dashboard',
  components: {
    appHeader: Header,
    appSideNav: SideNav,
    appLogin: Login,
  },
  data() {
    return {
      loginBox: false,
      recentOrders: [],
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    sideNavClosed() {
      return this.$store.getters.sideNavClosed;
    },
    mobileNavOpen() {
      return this.$store.getters.mobileNavOpen;
    },
    computedHeight() {
      return this.$store.getters.computedHeight;
    },
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    getRecentOrders() {
      axios.get(`/Orders/getRecentOrders.json?auth=${this.authData.token}&user=${this.authData.user}&cage=${this.authData.cage}`)
        .then((response) => {
          this.recentOrders = response.data.orders;
        });
    },
  },
  created() {
    this.$store.dispatch('clearHeight');
  },
  mounted() {
    this.getRecentOrders();
    this.$store.dispatch('computeHeight');
  },
};
</script>
