<template>
  <div>
    <router-link to="/" tag="a" class="system-nav__logo"> </router-link>
    <button
      v-if="mobileNavOpen === true"
      v-on:click="toggleMobileNav"
      data-direction="close"
      class="navigation__trigger close">&nbsp;</button>
  </div>
</template>

<script>
export default {
  name: 'TopLogo',
  computed: {
    mobileNavOpen() {
      return this.$store.getters.mobileNavOpen;
    },
  },
  methods: {
    toggleMobileNav(event) {
      const direction = event.currentTarget.getAttribute('data-direction');
      this.$store.dispatch('toggleMobileNav', { direction });
    },
  },
};
</script>
