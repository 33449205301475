<template>
  <div>
    <div
      v-bind:class="{ 'side-navigation--closed': sideNavClosed, 'mobile-open': mobileNavOpen }"
      class="side-navigation__container">
      <app-side-nav></app-side-nav>
    </div>
    <div
      v-bind:class="{ 'side-navigation--closed': sideNavClosed }"
      class="module__container">
      <app-header></app-header>
      <div class="grid-container pad-top">
        <div class="grid-x grid-margin-x">
          <div class="cell small-12">
            <h2>Garments</h2>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell small-6">
            <input type="text" v-model="searchText" placeholder="Product code" />
          </div>
          <div class="cell small-6">
            <button v-on:click="search" class="button">Search</button>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell small-12">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Product code</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(garment, index) in garments" v-bind:key="index">
                <td>{{ garment.name }}</td>
                <td>{{ garment.product_code }}</td>
                <td>{{ garment.image }}</td>
                <td>
                  <router-link v-bind:to="`/edit-garment/${garment.id}`">
                    <i class="material-icons">edit</i>
                  </router-link>
                  &nbsp;
                  <i
                    class="material-icons icon--red link"
                    @click="deleteGarment(index)">delete</i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Header from '@/components/Header/SystemTopNavigation.vue';
import SideNav from '@/components/SystemNavigation/DashboardNav.vue';
import axios from '../../axios';

export default {
  name: 'ViewAllGarments',
  components: {
    appHeader: Header,
    appSideNav: SideNav,
  },
  data() {
    return {
      garments: [],
      searchText: '',
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    sideNavClosed() {
      return this.$store.getters.sideNavClosed;
    },
    mobileNavOpen() {
      return this.$store.getters.mobileNavOpen;
    },
    computedHeight() {
      return this.$store.getters.computedHeight;
    },
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    search() {
      this.garments = [];
      axios.get(`/Garments/searchGarments.json?auth=${this.authData.token}&user=${this.authData.user}&cage=${this.authData.cage}&term=${this.searchText}`)
        .then((response) => {
          this.garments = response.data.garments;
        });
    },
    getGarments() {
      axios.get(`/Garments/getGarments.json?auth=${this.authData.token}&user=${this.authData.user}&cage=${this.authData.cage}`)
        .then((response) => {
          this.garments = response.data.garments;
        });
    },
    deleteGarment(index) {
      const postData = {};
      postData.garment = this.garments[index];
      axios.post(`/Garments/archiveGarment.json?auth=${this.authData.token}&user=${this.authData.user}&cage=${this.authData.cage}`, postData)
        .then(() => {
          this.garments.splice(index, 1);
        });
    },
  },
  created() {
    this.$store.dispatch('clearHeight');
  },
  mounted() {
    this.getGarments();
    this.$store.dispatch('computeHeight');
  },
};
</script>
