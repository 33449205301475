import Vue from 'vue';
import Router from 'vue-router';
// eslint-disable-next-line
import store from '@/store/index';
import Dashboard from '@/components/Dashboard/Dashboard.vue';
import Customers from '@/components/Customers/Customers.vue';
import ViewCustomer from '@/components/Customers/View.vue';
import Enquiries from '@/components/Enquiries/Enquiries.vue';
import Login from '@/components/Auth/GetToken.vue';
import Uploader from '@/components/Uploader/Uploader.vue';
import ViewDesign from '@/components/Orders/ViewDesign.vue';
import ViewAllGarments from '@/components/Garments/ViewAll.vue';
import ViewGarment from '@/components/Garments/ViewSingle.vue';
import EditGarment from '@/components/Garments/Edit.vue';
import ViewAllOrders from '@/components/Orders/ViewAll.vue';
import ViewOrder from '@/components/Orders/View.vue';
import ShopGarments from '@/components/ShopGarments/ShopGarments.vue';
import AbandonedCarts from '@/components/Carts/AbandonedCarts.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      component: Dashboard,
    },
    {
      path: '/customers',
      name: 'Customers',
      component: Customers,
      beforeEnter(to, from, next) {
        if (!store.state.idToken) {
          store.dispatch('checkStoredToken');
        }
        if (store.state.idToken) {
          next();
        } else {
          next('/login');
        }
      },
    },
    {
      path: '/view-customer/:id',
      name: 'ViewCustomer',
      component: ViewCustomer,
      beforeEnter(to, from, next) {
        if (!store.state.idToken) {
          store.dispatch('checkStoredToken');
        }
        if (store.state.idToken) {
          next();
        } else {
          next('/login');
        }
      },
    },
    {
      path: '/enquiries',
      name: 'Enquiries',
      component: Enquiries,
      beforeEnter(to, from, next) {
        if (!store.state.idToken) {
          store.dispatch('checkStoredToken');
        }
        if (store.state.idToken) {
          next();
        } else {
          next('/login');
        }
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/uploader',
      name: 'Uploader',
      component: Uploader,
      beforeEnter(to, from, next) {
        if (!store.state.idToken) {
          store.dispatch('checkStoredToken');
        }
        if (store.state.idToken) {
          next();
        } else {
          next('/login');
        }
      },
    },
    {
      path: '/view-design/:id',
      name: 'ViewDesign',
      component: ViewDesign,
      beforeEnter(to, from, next) {
        if (!store.state.idToken) {
          store.dispatch('checkStoredToken');
        }
        if (store.state.idToken) {
          next();
        } else {
          next('/login');
        }
      },
    },
    {
      path: '/view-order/:id',
      name: 'ViewOrder',
      component: ViewOrder,
      beforeEnter(to, from, next) {
        if (!store.state.idToken) {
          store.dispatch('checkStoredToken');
        }
        if (store.state.idToken) {
          next();
        } else {
          next('/login');
        }
      },
    },
    {
      path: '/view-all-orders',
      name: 'ViewAllOrders',
      component: ViewAllOrders,
      beforeEnter(to, from, next) {
        if (!store.state.idToken) {
          store.dispatch('checkStoredToken');
        }
        if (store.state.idToken) {
          next();
        } else {
          next('/login');
        }
      },
    },
    {
      path: '/view-all-garments',
      name: 'ViewAllGarments',
      component: ViewAllGarments,
      beforeEnter(to, from, next) {
        if (!store.state.idToken) {
          store.dispatch('checkStoredToken');
        }
        if (store.state.idToken) {
          next();
        } else {
          next('/login');
        }
      },
    },
    {
      path: '/view-garment/:id',
      name: 'ViewGarment',
      component: ViewGarment,
      beforeEnter(to, from, next) {
        if (!store.state.idToken) {
          store.dispatch('checkStoredToken');
        }
        if (store.state.idToken) {
          next();
        } else {
          next('/login');
        }
      },
    },
    {
      path: '/edit-garment/:id',
      name: 'EditGarment',
      component: EditGarment,
      beforeEnter(to, from, next) {
        if (!store.state.idToken) {
          store.dispatch('checkStoredToken');
        }
        if (store.state.idToken) {
          next();
        } else {
          next('/login');
        }
      },
    },
    {
      path: '/view-shop-garments',
      name: 'ShopGarments',
      component: ShopGarments,
      beforeEnter(to, from, next) {
        if (!store.state.idToken) {
          store.dispatch('checkStoredToken');
        }
        if (store.state.idToken) {
          next();
        } else {
          next('/login');
        }
      },
    },
    {
      path: '/abandoned-carts',
      name: 'AbandonedCarts',
      component: AbandonedCarts,
      beforeEnter(to, from, next) {
        if (!store.state.idToken) {
          store.dispatch('checkStoredToken');
        }
        if (store.state.idToken) {
          next();
        } else {
          next('/login');
        }
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});
export default router;
