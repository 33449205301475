<template>
  <div v-bind:class="{ 'shift-header': absoluteHeader }" id="app">
    <app-styles></app-styles>
    <router-view></router-view>
  </div>
</template>

<script>
import Styles from '@/components/Ztyles/Styles.vue';

export default {
  name: 'app',
  components: {
    'app-styles': Styles,
  },
  computed: {
    absoluteHeader() {
      return this.$store.getters.absoluteHeader;
    },
  },
  beforeCreate() {
    if (!this.$store.state.idToken) {
      this.$store.dispatch('checkStoredToken');
    }
  },
};
</script>
