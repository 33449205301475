<template>
  <div>
    <div
      v-bind:class="{ 'side-navigation--closed': sideNavClosed, 'mobile-open': mobileNavOpen }"
      class="side-navigation__container">
      <app-side-nav></app-side-nav>
    </div>
    <div
      v-bind:class="{ 'side-navigation--closed': sideNavClosed }"
      class="module__container">
      <app-header></app-header>
      <div class="grid-container pad-top">
        <div class="grid-x grid-margin-x">
          <div class="cell small-12">
            <h2>View Customer</h2>
          </div>
        </div>
      </div>
      <div class="grid-container pad-top">
        <div class="grid-x grid-margin-x">
          <div class="cell small-12">
            <p>
              {{ customer.name }}<br />
              {{ customer.email }}<br />
              {{ customer.tel }}
            </p>
          </div>
        </div>
        <div
          v-if="customer.comments"
          class="grid-x grid-margin-x">
          <div class="cell small-12">
            <p>
              Comments
            </p>
            <p v-for="(comment, index) in customer.comments" :key="index">
              {{ comment.comment }}
            </p>
          </div>
        </div>
        <div
          v-if="customer.categories"
          class="grid-x grid-margin-x">
          <div class="cell small-12">
            <p>
              Categories
            </p>
            <p v-for="(category, cindex) in customer.categories" :key="cindex">
              {{ category }}
            </p>
          </div>
        </div>
        <div
          v-if="customer.addresses"
          class="grid-x grid-margin-x">
          <div class="cell small-12">
            <p>
              Addresses
            </p>
            <p v-for="(address, aindex) in customer.addresses" :key="aindex">
              {{ address.lineOne }}<br />
              <span v-if="address.lineTwo">{{ address.lineTwo }}<br /></span>
              {{ address.town }}<br />
              {{ address.postcode }}
            </p>
          </div>
        </div>
        <div
          v-if="customer.orders"
          class="grid-x grid-margin-x">
          <div class="cell small-12">
            <p>
              Orders
            </p>
            <p v-for="(order, oindex) in customer.orders" :key="oindex">
              #{{ order.id }} {{ order.date | prettyDate }} {{ order.status }}
              <router-link v-bind:to="`/view-order/${order.id}`">
                <i class="material-icons">visibility</i>
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header/SystemTopNavigation.vue';
import SideNav from '@/components/SystemNavigation/DashboardNav.vue';
import axios from '../../axios';

export default {
  name: 'ViewCustomer',
  components: {
    appHeader: Header,
    appSideNav: SideNav,
  },
  data() {
    return {
      customer: {},
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    sideNavClosed() {
      return this.$store.getters.sideNavClosed;
    },
    mobileNavOpen() {
      return this.$store.getters.mobileNavOpen;
    },
    computedHeight() {
      return this.$store.getters.computedHeight;
    },
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    getCustomer() {
      const customerId = this.$route.params.id;
      axios.get(`/Customers/getSingle/${customerId}.json?auth=${this.authData.token}&user=${this.authData.user}&cage=${this.authData.cage}`)
        .then((response) => {
          this.customer = response.data.customer;
        });
    },
  },
  created() {
    this.$store.dispatch('clearHeight');
  },
  mounted() {
    this.getCustomer();
    this.$store.dispatch('computeHeight');
  },
};
</script>
