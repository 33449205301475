<template>
  <div>
    <div
      class="settings">
      <span @click="moveUp">
        <img src="../../assets/arrow-up-thick.png" alt="Move Up" title="Move Up" />
      </span>
      <span @click="moveDown">
        <img src="../../assets/arrow-down-thick.png" alt="Move Down" title="Move Down" />
      </span>
      <span @click="remove">
        <img src="../../assets/delete.png" alt="Delete" title="Delete" />
      </span>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'Controls',
  props: [
    'garmentId',
    'category',
  ],
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
  },
  methods: {
    moveUp() {
      axios.get(`/garments/moveUp/${this.garmentId}/${this.category}.json?auth=${this.authData.token}&user=${this.authData.user}&cage=${this.authData.cage}`)
        .then(() => {
          this.$emit('actionComplete');
        });
    },
    moveDown() {
      axios.get(`/garments/moveDown/${this.garmentId}/${this.category}.json?auth=${this.authData.token}&user=${this.authData.user}&cage=${this.authData.cage}`)
        .then(() => {
          this.$emit('actionComplete');
        });
    },
    remove() {
      axios.get(`/Garments/removeShopGarment/${this.garmentId}.json?auth=${this.authData.token}&user=${this.authData.user}&cage=${this.authData.cage}`)
        .then(() => {
          this.$emit('actionComplete');
        });
    },
  },
};
</script>
