<template>
  <div>
    <div
      class="grid-x grid-padding-x mobile-header pad-top--small pad-bottom--small">
      <div class="cell small-9">
        <img
          width="200"
          src="../../../static/logos/cc_logo.png"
          alt="" />
      </div>
      <div class="cell small-3">
        <button
          v-if="mobileNavOpen === false"
          v-on:click="toggleMobileNav"
          data-direction="open"
          class="navigation__trigger">
          <i class="material-icons">reorder</i>
        </button>
        <button
          v-if="mobileNavOpen === true"
          v-on:click="toggleMobileNav"
          data-direction="close"
          class="navigation__trigger">
          <i class="material-icons">clear</i>
        </button>
      </div>
    </div>
    <nav class="grid-x grid-padding-x primary-navigation primary-navigation--system pad-top--small">
      <div class="cell small-5 text-left pad-top--small">
        Welcome back, {{ currentUserName }}
      </div>
      <div class="cell small-7">
        <router-link
          to="/"
          tag="button"
          class="button nav grey auth">
          dashboard
        </router-link>
        <button
          v-on:click="logout()"
          class="button nav auth">
          log Out
        </button>
      </div>
    </nav>
    <div class="modal dashboard message" v-if="globalMessage.length >= 1">
      <span v-on:click="emptyGlobalMessage()" class="modal__close">X</span>
        {{ globalMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SystemTopNavigation',
  computed: {
    globalMessage() {
      return this.$store.getters.globalMessage;
    },
    mobileNavOpen() {
      return this.$store.getters.mobileNavOpen;
    },
    currentUserName() {
      return this.$store.getters.currentUserName;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    emptyGlobalMessage() {
      this.$store.dispatch('emptyGlobalMessage');
    },
    toggleMobileNav(event) {
      const direction = event.currentTarget.getAttribute('data-direction');
      this.$store.dispatch('toggleMobileNav', { direction });
    },
  },
};
</script>
