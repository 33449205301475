<template>
  <div>
    <div
      v-bind:class="{ 'side-navigation--closed': sideNavClosed, 'mobile-open': mobileNavOpen }"
      class="side-navigation__container">
      <app-side-nav></app-side-nav>
    </div>
    <div
      v-bind:class="{ 'side-navigation--closed': sideNavClosed }"
      class="module__container">
      <app-header></app-header>
      <div class="grid-x grid-padding-x pad-top--large">
        <div class="cell small-12">
          <h1>
            View the design
            <span
              v-on:click="goBack"
              class="float-right button">
              Back to order details
            </span>
          </h1>
        </div>
      </div>
      <div class="grid-x grid-padding-x pad-top--large">
        <div class="cell small-12">
          <p>
            Name: {{ design.name }}<br />
            Notes: {{ design.notes }}<br />
            Type: {{ design.type }}<br />
            Position: {{ design.position }}<br />
            Size: {{ design.size }}
          </p>
          <p v-if="design.text">
            Text: {{ design.text }}<br />
            Font: {{ design.text_font }}<br />
            Size: {{ design.text_size }}<br />
          </p>
          <div v-else>
            <p>Artwork:</p>
            <img :src='`https://runsmart-dropship.s3.eu-west-2.amazonaws.com/new_uploads/original/${design.image}`' alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from '@/components/SystemNavigation/DashboardNav.vue';
import Header from '@/components/Header/SystemTopNavigation.vue';
import axios from '../../axios';

export default {
  name: 'PreviewDesign',
  components: {
    appHeader: Header,
    appSideNav: SideNav,
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    sideNavClosed() {
      return this.$store.getters.sideNavClosed;
    },
    mobileNavOpen() {
      return this.$store.getters.mobileNavOpen;
    },
    computedHeight() {
      return this.$store.getters.computedHeight;
    },
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  data() {
    return {
      design: {},
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getDesign() {
      const designId = this.$route.params.id;
      axios.get(`/Designs/getPreview/${designId}.json?auth=${this.authData.token}&user=${this.authData.user}&cage=${this.authData.cage}`)
        .then((response) => {
          this.design = response.data.design;
        });
    },
  },
  mounted() {
    this.getDesign();
  },
};
</script>
