<template>
  <div>
    <div
      v-bind:class="{ 'side-navigation--closed': sideNavClosed, 'mobile-open': mobileNavOpen }"
      class="side-navigation__container">
      <app-side-nav></app-side-nav>
    </div>
    <div
      v-bind:class="{ 'side-navigation--closed': sideNavClosed }"
      class="module__container">
      <app-header></app-header>
      <div class="grid-container pad-top">
        <div class="grid-x grid-margin-x">
          <div class="cell small-12">
            <h2>Order {{ order.id }}: {{ order.created | prettyDate }}</h2>
            <h3>
              Total: {{ order.amount | priceInPounds | currency }}, Status: {{ order.status }}
            </h3>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell small-12 medium-4">
            <label>Update Order Status</label>
            <select v-model="order.status">
              <option value="New">New</option>
              <option value="InProgress">InProgress</option>
              <option value="OnHold">OnHold</option>
              <option value="Complete">Complete</option>
            </select>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell small-12">
            <button v-on:click="saveStatus" class="button">Save Status</button>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell small-12 medium-4">
            <label>Delivery date</label>
            <input type="text" v-model="deliveryDate">
          </div>
          <div class="cell small-12 medium-4">
            <label>Consignment No</label>
            <input type="text" v-model="consignmentNo">
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell small-12 medium-4">
            <button v-on:click="sendEmail('approved')" class="button expanded">
              Order Approved Email
            </button>
          </div>
          <div class="cell small-12 medium-4">
            <button v-on:click="sendEmail('dispatched')" class="button expanded">
              Order Dispatched Email
            </button>
          </div>
          <div class="cell small-12 medium-4">
            <button v-on:click="sendEmail('invoiced')" class="button expanded">
              Invoice Email
            </button>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell small-12">
            <h4>Customer:</h4>
            <p>
              {{ order.customer.name }}<br />
              {{ order.customer.email }}<br />
              {{ order.customer.phone }}
            </p>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell small-12 medium-6">
            <p>
              <strong>Invoice Address</strong><br />
              <span
                v-if="order.invoice_address.line_one">
                {{ order.invoice_address.line_one }}<br />
              </span>
              <span
                v-if="order.invoice_address.line_two">
                {{ order.invoice_address.line_two }}<br />
              </span>
              <span
                v-if="order.invoice_address.town">
                {{ order.invoice_address.town }}<br />
              </span>
              <span
                v-if="order.invoice_address.postcode">
                {{ order.invoice_address.postcode }}
              </span>
            </p>
          </div>
          <div class="cell small-12 medium-6">
            <p>
              <strong>Delivery Address</strong><br />
              <span
                v-if="order.delivery_address.line_one">
                {{ order.delivery_address.line_one }}<br />
              </span>
              <span
                v-if="order.delivery_address.line_two">
                {{ order.delivery_address.line_two }}<br />
              </span>
              <span
                v-if="order.delivery_address.town">
                {{ order.delivery_address.town }}<br />
              </span>
              <span
                v-if="order.delivery_address.postcode">
                {{ order.delivery_address.postcode }}
              </span>
            </p>
            <p v-if="order.delivery_address.notes">
              <strong>Notes:</strong><br />
              {{ order.delivery_address.notes }}
            </p>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell small-12">
            <h3>Garments</h3>
          </div>
        </div>
        <div
          v-for="(line, index) in order.garments" v-bind:key="index"
          class="grid-x grid-margin-x">
          <div class="cell small-12">
            <div class="pad bordered">
              <p>
                {{ line.quantity }} x {{ line.product_code }} | {{ line.name }} | {{ line.size }} |
                {{ line.colour }}
              </p>
            </div>
          </div>
        </div>
        <div class="grid-x grid-margin-x pad-top">
          <div class="cell small-12">
            <h3>Designs</h3>
          </div>
        </div>
        <div
          v-for="(design, dindex) in order.designs" v-bind:key="dindex"
          class="grid-x grid-margin-x">
          <div class="cell small-12">
            <div class="pad bordered">
              <div class="grid-x grid-padding-x">
                <div class="cell small-12 medium-9">
                  {{ design.name }},
                  {{ design.type }},
                  {{ design.position }},
                  {{ design.size }}cm
                </div>
                <div class="cell small-12 medium-3">
                  <router-link v-bind:to="`/view-design/${design.id}`">View design</router-link>
                  <br />
                  <a
                    target="_blank"
                    download
                    :href="`https://runsmart-dropship.s3.eu-west-2.amazonaws.com/new_uploads/original/${design.image}`">
                    Download File
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showMessage" class="modal__wrap">
      <div class="modal__inner">
        <span @click="showMessage = false" class="modal__close">X</span>
        <div class="message text-center pad-bottom">
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header/SystemTopNavigation.vue';
import SideNav from '@/components/SystemNavigation/DashboardNav.vue';
import axios from '../../axios';

export default {
  name: 'ViewOrder',
  components: {
    appHeader: Header,
    appSideNav: SideNav,
  },
  data() {
    return {
      loginBox: false,
      order: {
        customer: {},
        invoice_address: {},
        delivery_address: {},
      },
      deliveryDate: '',
      consignmentNo: '',
      message: '',
      showMessage: false,
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    sideNavClosed() {
      return this.$store.getters.sideNavClosed;
    },
    mobileNavOpen() {
      return this.$store.getters.mobileNavOpen;
    },
    computedHeight() {
      return this.$store.getters.computedHeight;
    },
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    sendEmail(type) {
      const postData = {};
      postData.type = type;
      postData.deliveryDate = this.deliveryDate;
      postData.consignmentNumber = this.consignmentNo;
      const orderId = this.$route.params.id;
      axios.post(`/Orders/sendEmail/${orderId}.json?auth=${this.authData.token}&user=${this.authData.user}&cage=${this.authData.cage}`, postData)
        .then((response) => {
          if (response.data.response === true) {
            this.message = 'The email has been sent.';
          } else {
            this.message = 'The email could not be sent. Please check the customer\'s email address';
          }
          this.showMessage = true;
        });
    },
    saveStatus() {
      const postData = {};
      postData.status = this.order.status;
      const orderId = this.$route.params.id;
      axios.post(`/Orders/saveStatus/${orderId}.json?auth=${this.authData.token}&user=${this.authData.user}&cage=${this.authData.cage}`, postData);
    },
    getOrder() {
      const orderId = this.$route.params.id;
      axios.get(`/Orders/getOrder/${orderId}.json?auth=${this.authData.token}&user=${this.authData.user}&cage=${this.authData.cage}`)
        .then((response) => {
          this.order = response.data.order;
        });
    },
  },
  created() {
    this.$store.dispatch('clearHeight');
  },
  mounted() {
    this.getOrder();
    this.$store.dispatch('computeHeight');
  },
};
</script>
