<template>
  <div>
    <div
      v-bind:class="{ 'side-navigation--closed': sideNavClosed, 'mobile-open': mobileNavOpen }"
      class="side-navigation__container">
      <app-side-nav></app-side-nav>
    </div>
    <div
      v-bind:class="{ 'side-navigation--closed': sideNavClosed }"
      class="module__container">
      <app-header></app-header>
      <div class="grid-container pad-top">
        <div class="grid-x grid-margin-x">
          <div class="cell small-12">
            <h2>Customers</h2>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell small-4">
            <download-csv
              name="customersWithOrders.csv"
              :data= "customersWithOrders">
              <button class="button">Export customers</button>
            </download-csv>
          </div>
          <div class="cell small-4">
            <download-csv
              name="customersWithoutOrders.csv"
              :data= "customersWithoutOrders">
              <button class="button">Export registered users</button>
            </download-csv>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell small-12">
            <table>
              <thead>
                <tr>
                  <th>Ref</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Telephone</th>
                  <th>Date Created</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(customer, index) in customers" v-bind:key="index">
                  <td>{{ customer.id }}</td>
                  <td>{{ customer.name }}</td>
                  <td>{{ customer.email }}</td>
                  <td>{{ customer.telephone }}</td>
                  <td>{{ customer.created | prettyDate }}</td>
                  <td>
                    <router-link v-bind:to="`/view-customer/${customer.id}`">
                      <i class="material-icons">visibility</i>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header/SystemTopNavigation.vue';
import SideNav from '@/components/SystemNavigation/DashboardNav.vue';
import axios from '../../axios';

export default {
  name: 'Enquiries',
  components: {
    appHeader: Header,
    appSideNav: SideNav,
  },
  data() {
    return {
      customers: [],
      customersWithOrders: [],
      customersWithoutOrders: [],
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    sideNavClosed() {
      return this.$store.getters.sideNavClosed;
    },
    mobileNavOpen() {
      return this.$store.getters.mobileNavOpen;
    },
    computedHeight() {
      return this.$store.getters.computedHeight;
    },
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    getCustomers() {
      axios.get(`/Customers/getAll.json?auth=${this.authData.token}&user=${this.authData.user}&cage=${this.authData.cage}`)
        .then((response) => {
          this.customers = response.data.customers;
          this.customersWithOrders = response.data.customersWithOrders;
          this.customersWithoutOrders = response.data.customersWithoutOrders;
        });
    },
  },
  created() {
    this.$store.dispatch('clearHeight');
  },
  mounted() {
    this.getCustomers();
    this.$store.dispatch('computeHeight');
  },
};
</script>
