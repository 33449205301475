<template>
  <div>
    <div
      v-bind:class="{ 'side-navigation--closed': sideNavClosed, 'mobile-open': mobileNavOpen }"
      class="side-navigation__container">
      <app-side-nav></app-side-nav>
    </div>
  <div
    v-bind:class="{ 'side-navigation--closed': sideNavClosed }"
    class="module__container">
    <app-header></app-header>
    <div class="grid-container pad-top">
      <div class="grid-x grid-margin-x">
        <div class="cell small-12">
          <h2>View Garment</h2>
        </div>
      </div>
      <div class="grid-x grid-margin-x">
        <div class="cell small-12">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Product code</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ garment.id }}</td>
                <td>{{ garment.name }}</td>
                <td>{{ garment.image }}</td>
                <td>
                  <router-link v-bind:to="`/edit-garment/${garment.id}`">
                    <i class="material-icons">edit</i>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Header from '@/components/Header/SystemTopNavigation.vue';
import SideNav from '@/components/SystemNavigation/DashboardNav.vue';
import axios from '../../axios';

export default {
  name: 'ViewGarment',
  components: {
    appHeader: Header,
    appSideNav: SideNav,
  },
  data() {
    return {
      garment: [],
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    sideNavClosed() {
      return this.$store.getters.sideNavClosed;
    },
    mobileNavOpen() {
      return this.$store.getters.mobileNavOpen;
    },
    computedHeight() {
      return this.$store.getters.computedHeight;
    },
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    getGarment() {
      const garmentId = this.$route.params.id;
      axios.get(`/Garments/getGarment/${garmentId}.json?auth=${this.authData.token}&user=${this.authData.user}&cage=${this.authData.cage}`)
        .then((response) => {
          this.garment = response.data.garment;
        });
    },
  },
  created() {
    this.$store.dispatch('clearHeight');
  },
  mounted() {
    this.getGarment();
    this.$store.dispatch('computeHeight');
  },
};
</script>
