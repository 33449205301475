<template>
  <div>
    <div
      v-bind:class="{ 'side-navigation--closed': sideNavClosed, 'mobile-open': mobileNavOpen }"
      class="side-navigation__container">
      <app-side-nav></app-side-nav>
    </div>
    <div
      v-bind:class="{ 'side-navigation--closed': sideNavClosed }"
      class="module__container">
      <app-header></app-header>
      <div class="grid-container pad-top">
        <div class="grid-x grid-margin-x">
          <div class="cell small-12">
            <h2>Abandoned Carts</h2>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell small-12">
            <div
              v-for="(cart, index) in abandonedCarts"
              :key="index"
              class="pad-top">
              Customer:<br />
              {{ cart.customer.first_name }} {{ cart.customer.last_name}}<br />
              {{ cart.customer.email }}<br />
              {{ cart.customer.mobile }}<br />
              Items:<br />
              <span v-for="(item, index) in cart.basket_items" :key="index">
                <span v-if="item.type === 1">
                  {{ item.quantity }} * {{ item.garment_colour_size.garment.name }}
                  {{ item.garment_colour_size.size }}
                  {{ item.garment_colour_size.garment_colour.name }}
                   | Added: {{ item.created | prettyDate }}
                  <br />
                </span>
                <span v-else>
                  {{ item.design.name }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header/SystemTopNavigation.vue';
import SideNav from '@/components/SystemNavigation/DashboardNav.vue';
import axios from '../../axios';

export default {
  name: 'Enquiries',
  components: {
    appHeader: Header,
    appSideNav: SideNav,
  },
  data() {
    return {
      abandonedCarts: [],
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    sideNavClosed() {
      return this.$store.getters.sideNavClosed;
    },
    mobileNavOpen() {
      return this.$store.getters.mobileNavOpen;
    },
    computedHeight() {
      return this.$store.getters.computedHeight;
    },
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    getCarts() {
      axios.get(`/Baskets/getAbandoned.json?auth=${this.authData.token}&user=${this.authData.user}&cage=${this.authData.cage}`)
        .then((response) => {
          this.abandonedCarts = response.data.baskets;
        });
    },
  },
  created() {
    this.$store.dispatch('clearHeight');
  },
  mounted() {
    this.getCarts();
    this.$store.dispatch('computeHeight');
  },
};
</script>
