<template>
  <div>
    <div
      v-bind:class="{ 'side-navigation--closed': sideNavClosed, 'mobile-open': mobileNavOpen }"
      class="side-navigation__container">
      <app-side-nav></app-side-nav>
    </div>
    <div
      v-bind:class="{ 'side-navigation--closed': sideNavClosed }"
      class="module__container">
      <app-header></app-header>
      <div class="grid-container pad-top">
        <div class="grid-x grid-margin-x">
          <div class="cell small-12">
            <h2 class="text-center">Garments showing on the website</h2>
          </div>
        </div>
        <div class="grid-x grid-margin-x pad-bottom">
          <div class="cell small-12 medium-3 medium-offset-3">
            <label>Category</label>
            <select v-model="category">
              <option value=0>All</option>
              <option value=1>Polo Shirt</option>
              <option value=2>T-Shirt</option>
              <option value=3>Trousers</option>
              <option value=4>Shorts</option>
              <option value=5>Sweatshirt</option>
              <option value=6>Hoodie</option>
              <option value=7>Fleece</option>
              <option value=8>Bodywarmer</option>
              <option value=9>Jacket</option>
              <option value=10>Hi-Viz</option>
            </select>
          </div>
          <div class="cell small-12 medium-3" style="padding-top:32px;">
            <button
              v-if="category > 0"
              @click="addGarment = true"
              class="button">Add To Category</button>
          </div>
        </div>
        <div
          v-if="addGarment"
          class="grid-x grid-padding-x">
          <div class="cell small-6 medium-3 medium-offset-3">
            <input type="text" v-model="searchText" placeholder="Product code" />
          </div>
          <div class="cell small-6 medium-3">
            <button v-on:click="search" class="button expanded">Search</button>
          </div>
        </div>
        <div v-if="addGarment" class="grid-x grid-padding-x">
          <div class="cell small-6 small-offset-3">
            <div class="grid-x">
              <div v-for="(garment, gindex) in garments" :key="gindex" class="cell small-6">
                <div
                  class="product-card">
                  <div>
                    <img
                      :src="`https://our-catalogue.com/RUNS/images/${garment.product_code}.jpg?width=300`"
                      alt="Garment" />
                    <div class="product-name mt-4">{{ garment.short_name }}</div>
                    <div class="product-code">{{ garment.product_code }}</div>
                  </div>
                  <div class="price relative">
                    From:
                    <span class="text-green text-heavy">
                      {{ garment.price | currency }}
                    </span>
                  </div>
                </div>
                <button
                  @click="useGarment = gindex"
                  class="button expanded">
                  Use this garment
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="category === 0 || category === '0'">
          <div class="grid-x">
            <div class="cell small-3 small-offset-3">
              <div class="grid-x">
                <div
                  v-for="(garment, index) in budgetGarments"
                  :key="index"
                  class="cell small-12">
                  <div
                    @click="editGarment('budget', index)"
                    class="product-card">
                    <div>
                      <img
                        :src="`https://our-catalogue.com/RUNS/images/${garment.product_code}.jpg?width=300`"
                        alt="Garment" />
                      <div class="product-name mt-4">{{ garment.short_name }}</div>
                      <div class="product-code">{{ garment.product_code }}</div>
                    </div>
                    <div class="price relative">
                      From:
                      <span class="text-green text-heavy">
                        {{ garment.price | currency }}
                      </span>
                    </div>
                  </div>
                  <div class="text-center">
                    <app-controls
                      :category="category"
                      :garmentId="garment.id"
                      @actionComplete="getGarments"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="cell small-3">
              <div class="grid-x">
                <div
                  v-for="(garment, index) in premiumGarments"
                  :key="index"
                  class="cell small-12">
                  <div
                    @click="editGarment('premium', index)"
                    class="product-card">
                    <div>
                      <img
                        :src="`https://our-catalogue.com/RUNS/images/${garment.product_code}.jpg?width=300`"
                        alt="Garment" />
                      <div class="product-name mt-4">{{ garment.short_name }}</div>
                      <div class="product-code">{{ garment.product_code }}</div>
                    </div>
                    <div class="price relative">
                      From:
                      <span class="text-green text-heavy">
                        {{ garment.price | currency }}
                      </span>
                    </div>
                  </div>
                  <div class="text-center">
                    <app-controls
                      :category="category"
                      :garmentId="garment.id"
                      @actionComplete="getGarments"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="grid-x">
            <div class="cell small-6 small-offset-3">
              <div class="grid-x">
                <div
                  v-for="(garment, index) in allGarments"
                  :key="index"
                  class="cell small-6">
                  <div
                    @click="editGarment('all', index)"
                    class="product-card">
                    <div>
                      <img
                        :src="`https://our-catalogue.com/RUNS/images/${garment.product_code}.jpg?width=300`"
                        alt="Garment" />
                      <div class="product-name mt-4">{{ garment.short_name }}</div>
                      <div class="product-code">{{ garment.product_code }}</div>
                    </div>
                    <div class="price relative">
                      From:
                      <span class="text-green text-heavy">
                        {{ garment.price | currency }}
                      </span>
                    </div>
                  </div>
                  <div class="text-center">
                    <app-controls
                      :category="category"
                      :garmentId="garment.id"
                      @actionComplete="getGarments"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="useGarment !== false" class="modal__wrap">
      <div class="modal__inner">
        <span class="modal__close" @click="useGarment = false">X</span>
        <div class="grid-x grid-margin-x">
          <div class="cell small-12 medium-4">
            <label>Budget or Premium</label>
            <select v-model="garment.use_for">
              <option value="0">Don't use</option>
              <option value="1">Budget</option>
              <option value="2">Premium</option>
            </select>
            <p class="help-text">
              Select budget or premium to show this item on the website
            </p>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell small-12 medium-4">
            <label>Short Name</label>
            <input v-model="garment.short_name" type="text" />
            <p class="help-text">
              The short name for the garment to show on the garment listings pages
            </p>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell small-12 medium-4">
            <label>Price</label>
            <input v-model="garment.price" type="number" min="0" step="0.01" />
            <p class="help-text">
              The price for the garment
            </p>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell small-12 medium-4">
            <button @click="saveSelection" class="button">Save</button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal__wrap"
      v-if="garmentInEdit">
      <div class="modal__inner high">
        <span @click="garmentInEdit = null" class="modal__close">X</span>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <h4>Edit {{ garmentInEdit.product_code }}</h4>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-3">
            <label>Price</label>
            <input v-model="garmentInEdit.price" type="number" step="0.01" min="0" />
          </div>
          <div class="cell small-12 medium-3">
            <label>Short name</label>
            <input v-model="garmentInEdit.short_name" type="text" />
          </div>
          <div class="cell small-12 medium-3">
            <label>Budget / Premium</label>
            <select v-model="garmentInEdit.type">
              <option value="budget">Budget</option>
              <option value="premium">Premium</option>
            </select>
          </div>
          <div class="cell small-12 medium-3">
            <label>Category</label>
            <select v-model="garmentInEdit.customer_category">
              <option value=0>All</option>
              <option value=1>Polo Shirt</option>
              <option value=2>T-Shirt</option>
              <option value=3>Trousers</option>
              <option value=4>Shorts</option>
              <option value=5>Sweatshirt</option>
              <option value=6>Hoodie</option>
              <option value=7>Fleece</option>
              <option value=8>Bodywarmer</option>
              <option value=9>Jacket</option>
              <option value=10>Hi-Viz</option>
            </select>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-6">
            <vue-editor v-bind:editor-toolbar="customToolbar" v-model="garmentInEdit.description" />
            <p class="help-text pad-top">
              The description for the garment
            </p>
          </div>
          <div class="cell small-12 medium-6">
            <label>Show on home page?</label>
            <input type="checkbox" v-model="garmentInEdit.on_home_page" />
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-3">
            <button @click="saveEditedGarment" class="button expanded">Save Changes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header/SystemTopNavigation.vue';
import SideNav from '@/components/SystemNavigation/DashboardNav.vue';
import Controls from '@/components/Controls/Controls.vue';
import { VueEditor } from 'vue2-editor';
import axios from '../../axios';

export default {
  name: 'ShopGarments',
  components: {
    appControls: Controls,
    appHeader: Header,
    appSideNav: SideNav,
    VueEditor,
  },
  data() {
    return {
      budgetGarments: [],
      premiumGarments: [],
      garments: [],
      allGarments: [],
      garment: {},
      category: 0,
      addGarment: false,
      searchText: '',
      useGarment: false,
      garmentInEdit: null,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        ['code-block'],
        [{ color: [] }],
      ],
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    sideNavClosed() {
      return this.$store.getters.sideNavClosed;
    },
    mobileNavOpen() {
      return this.$store.getters.mobileNavOpen;
    },
    computedHeight() {
      return this.$store.getters.computedHeight;
    },
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  watch: {
    category() {
      this.getGarments();
    },
    useGarment(value) {
      this.garment = this.garments[value];
    },
  },
  methods: {
    saveEditedGarment() {
      axios.post(`/Garments/saveShopGarment.json?auth=${this.authData.token}&user=${this.authData.user}&cage=${this.authData.cage}`, this.garmentInEdit)
        .then(() => {
          this.getGarments();
          this.garmentInEdit = null;
        });
    },
    editGarment(type, index) {
      if (type === 'budget') {
        this.garmentInEdit = this.budgetGarments[index];
      } else if (type === 'premium') {
        this.garmentInEdit = this.premiumGarments[index];
      } else {
        this.garmentInEdit = this.allGarments[index];
      }
      this.garmentInEdit.type = type;
    },
    getGarments() {
      this.budgetGarments = [];
      this.premiumGarments = [];
      axios.get(`/Garments/getShopGarments/${this.category}.json?auth=${this.authData.token}&user=${this.authData.user}&cage=${this.authData.cage}`)
        .then((response) => {
          if (this.category === 0 || this.category === '0') {
            this.budgetGarments = response.data.response.budgetGarments;
            this.premiumGarments = response.data.response.premiumGarments;
          } else {
            this.allGarments = response.data.response.allGarments;
          }
        });
    },
    saveSelection() {
      const postData = {};
      postData.garment = this.garment;
      postData.category = this.category;
      axios.post(`/Garments/saveSelection.json?auth=${this.authData.token}&user=${this.authData.user}&cage=${this.authData.cage}`, postData)
        .then(() => {
          this.addGarment = false;
          this.searchText = '';
          this.useGarment = false;
          this.getGarments();
        });
    },
    search() {
      axios.get(`/Garments/searchGarmentsToAdd.json?auth=${this.authData.token}&user=${this.authData.user}&cage=${this.authData.cage}&term=${this.searchText}`)
        .then((response) => {
          this.garments = response.data.response.garments;
        });
    },
  },
  created() {
    this.$store.dispatch('clearHeight');
  },
  mounted() {
    this.getGarments();
    this.$store.dispatch('computeHeight');
  },
};
</script>
