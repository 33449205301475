import Vue from 'vue';
import { format } from 'date-fns';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import Vuex from 'vuex';
import JsonCSV from 'vue-json-csv';
import App from './App.vue';
import router from './router';
import store from './store/index';

Vue.use(Vuex);
Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' });

Vue.component('downloadCsv', JsonCSV);

Vue.config.productionTip = false;

Vue.filter('prettyDate', (value) => {
  if (!value) return '';
  const prettyDate = format(new Date(value), 'EEEE do MMMM yyy');
  return prettyDate;
});
Vue.filter('tinyDate', (value) => {
  if (!value) return '';
  const prettyDate = format(new Date(value), 'd-MM-yy');
  return prettyDate;
});
Vue.filter('priceInPounds', (value) => {
  if (!value) return '';
  const priceInPounds = (value / 100);
  return priceInPounds;
});

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount('#app');
