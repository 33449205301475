<template>
  <div>
    <div
      v-bind:class="{ 'side-navigation--closed': sideNavClosed, 'mobile-open': mobileNavOpen }"
      class="side-navigation__container">
      <app-side-nav></app-side-nav>
    </div>
  <div
    v-bind:class="{ 'side-navigation--closed': sideNavClosed }"
    class="module__container">
    <app-header></app-header>
    <div class="grid-container pad-top">
      <div class="grid-x grid-margin-x">
        <div class="cell small-12">
          <h2>Edit {{ garment.name }} | {{ garment.product_code }}</h2>
        </div>
      </div>
      <div class="grid-x grid-margin-x">
        <div class="cell small-12 medium-4">
          <label>Budget or Premium</label>
          <select v-model="garment.use_for">
            <option value="0">Don't use</option>
            <option value="1">Budget</option>
            <option value="2">Premium</option>
          </select>
          <p class="help-text">
            Select budget or premium to show this item on the website
          </p>
        </div>
      </div>
      <div class="grid-x grid-margin-x">
        <div class="cell small-12 medium-4">
          <label>Show on home page?</label>
          <input type="checkbox" v-model="garment.on_home_page" />
          <p class="help-text">
            Show this garment on the home page of the website?
          </p>
        </div>
      </div>
      <div class="grid-x grid-margin-x">
        <div class="cell small-12 medium-4">
          <label>Short Name</label>
          <input v-model="garment.short_name" type="text" />
          <p class="help-text">
            The short name for the garment to show on the garment listings pages
          </p>
        </div>
      </div>
      <div class="grid-x grid-margin-x">
        <div class="cell small-12 medium-4">
          <label>Price</label>
          <input v-model="garment.price" type="number" min="0" step="0.01" />
          <p class="help-text">
            The price for the garment
          </p>
        </div>
      </div>
      <div class="grid-x grid-margin-x">
        <div class="cell small-12 medium-4">
          <label>Show in interest category</label>
          <input v-model="garment.interest_category" type="checkbox" />
          <p class="help-text">
            Show this product if the user has selected it's category
            ({{ garment.category.category }}) is one they are interested in
          </p>
        </div>
      </div>
      <div class="grid-x grid-margin-x">
        <div class="cell small-12 pad-bottom pad-top">
          <vue-editor v-bind:editor-toolbar="customToolbar" v-model="garment.description" />
          <p class="help-text pad-top">
            The description for the garment
          </p>
        </div>
      </div>
      <div class="grid-x grid-margin-x">
        <div class="cell small-12">
          <button v-on:click="saveGarment" class="button">Save</button>
        </div>
      </div>
    </div>
  </div>
  <div class="saving-indicator" v-if="saving === true">
    <h4>Saving ...</h4>
  </div>
</div>
</template>

<script>
import Header from '@/components/Header/SystemTopNavigation.vue';
import SideNav from '@/components/SystemNavigation/DashboardNav.vue';
import { VueEditor } from 'vue2-editor';
import axios from '../../axios';

export default {
  name: 'EditGarment',
  components: {
    appHeader: Header,
    appSideNav: SideNav,
    VueEditor,
  },
  data() {
    return {
      garment: {
        description: '',
        use_for: 0,
        short_name: '',
        price: 0,
        interest_category: false,
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        ['code-block'],
        [{ color: [] }],
      ],
      saving: false,
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    sideNavClosed() {
      return this.$store.getters.sideNavClosed;
    },
    mobileNavOpen() {
      return this.$store.getters.mobileNavOpen;
    },
    computedHeight() {
      return this.$store.getters.computedHeight;
    },
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    saveGarment() {
      this.saving = true;
      const postData = this.garment;
      axios.post(`/Garments/saveGarment.json?auth=${this.authData.token}&user=${this.authData.user}&cage=${this.authData.cage}`, postData)
        .then((response) => {
          this.garment = response.data.garment;
          setTimeout(() => {
            this.saving = false;
          }, 300);
        });
    },
    getGarment() {
      const garmentId = this.$route.params.id;
      axios.get(`/Garments/getGarment/${garmentId}.json?auth=${this.authData.token}&user=${this.authData.user}&cage=${this.authData.cage}`)
        .then((response) => {
          this.garment = response.data.garment;
        });
    },
  },
  created() {
    this.$store.dispatch('clearHeight');
  },
  mounted() {
    this.getGarment();
    this.$store.dispatch('computeHeight');
  },
};
</script>
