<template>
  <div>
    <div v-if="result === false">
      <h1>Customised Clothing Admin</h1>
      <h2>Log In</h2>
      <p>Enter your email address below. We'll send you a magic login link.</p>
      <input
        v-model="email"
        v-on:focus="message = ''"
        type="email"
        placeholder="Your registered email address">
      <button
        v-on:click="login"
        class="button">Log In</button>
    </div>
    <p v-if="message.length >= 1">{{ message }}</p>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      message: '',
      result: false,
    };
  },
  methods: {
    login() {
      const data = {
        email: this.email,
      };
      axios.post('/UserAuth/requestToken.json', data)
        .then((res) => {
          if (res.data.response === true) {
            this.message = 'We have sent you a login link. Please check your email.';
            this.result = true;
          }
          if (res.data.response === false) {
            this.message = 'Sorry, we couldn\'t find that email. Please try again.';
          }
        })
        .then((error) => {
          if (error) {
            this.message = 'Sorry, we couldn\'t find that email. Please try again.';
          }
        });
    },
    closeLogin() {
      this.$store.dispatch('closeLogin');
      this.message = '';
    },
  },
};
</script>
